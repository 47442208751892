<script lang="ts" setup>
import type { BaseImageFragment } from "@/types/graphql";

const props = defineProps<{
  mime: string;
  url: string;
  alternativeText?: string | null;
  caption?: string | null;
  eagerLoading?: boolean | null;
  ext?: string | null;
  fetchPriority?: boolean | null;
  formats?: any | null;
  hash?: string;
  height?: number | null;
  name?: string;
  previewUrl?: string | null;
  provider?: string;
  provider_metadata?: any | null;
  size?: number;
  sizes?: string | null;
  width?: number | null;
}>();

const imageSharpFormat = 'avif';
const imageSharpQuality = '60';
const imageSharpMaxWidth = '2560';

const imageSharpSettingsString = `?format=${imageSharpFormat}&quality=${imageSharpQuality}`;
const imageSharpMaxWidthString = `&width=${imageSharpMaxWidth}`;

const config = useRuntimeConfig();
const imagePoviderUrl = config.public.apiBase;
const responsiveImages = computed(() => {
  const images = props.formats;
  if (!images) return {};
  const keys = Object.keys(images);
  const values = Object.values(images);
  const srcset = values.map(
    (value) => {
      if (value.width <= imageSharpMaxWidth) {
        return `${imagePoviderUrl}${value.url}${imageSharpSettingsString} ${value.width}w`;
      }
    }
  );
  return {
    srcset: srcset.join(", "),
    sizes: props.sizes ?? "100vw",
  };
});
</script>
<template>
  <img
    :src="`${imagePoviderUrl}${props.url}${imageSharpSettingsString}${imageSharpMaxWidthString}`"
    :alt="props.alternativeText"
    :sizes="responsiveImages.sizes"
    :srcset="responsiveImages.srcset"
    :loading="props.eagerLoading ? 'eager' : 'lazy'"
    :fetchpriority="props.fetchPriority ? 'high' : 'auto'"
  />
</template>
